(function(a) {
    a.isScrollToFixed = function(b) {
        return !!a(b).data('ScrollToFixed');
    };
    a.ScrollToFixed = function(d, i) {
        var l = this;
        l.$el = a(d);
        l.el = d;
        l.$el.data('ScrollToFixed', l);
        var c = false;
        var G = l.$el;
        var H;
        var E;
        var e;
        var y;
        var D = 0;
        var q = 0;
        var j = -1;
        var f = -1;
        var t = null;
        var z;
        var g;
        function u() {
            G.trigger('preUnfixed.ScrollToFixed');
            k();
            G.trigger('unfixed.ScrollToFixed');
            f = -1;
            D = G.offset().top;
            q = G.offset().left;
            if (l.options.offsets) {
                q += G.offset().left - G.position().left;
            }
            if (j == -1) {
                j = q;
            }
            H = G.css('position');
            c = true;
            if (l.options.bottom != -1) {
                G.trigger('preFixed.ScrollToFixed');
                w();
                G.trigger('fixed.ScrollToFixed');
            }
        }
        function n() {
            var I = l.options.limit;
            if (!I) {
                return 0;
            }
            if (typeof I === 'function') {
                return I.apply(G);
            }
            return I;
        }
        function p() {
            return H === 'fixed';
        }
        function x() {
            return H === 'absolute';
        }
        function h() {
            return !(p() || x());
        }
        function w() {
            if (!p()) {
                t.css({
                    display: G.css('display'),
                    width: G.outerWidth(true),
                    height: G.outerHeight(true),
                    float: G.css('float')
                });
                cssOptions = {
                    'z-index': l.options.zIndex,
                    position: 'fixed',
                    top: l.options.bottom == -1 ? s() : '',
                    bottom: l.options.bottom == -1 ? '' : l.options.bottom,
                    'margin-left': '0px'
                };
                if (!l.options.dontSetWidth) {
                    cssOptions.width = G.width();
                }
                G.css(cssOptions);
                G.addClass(l.options.baseClassName);
                if (l.options.className) {
                    G.addClass(l.options.className);
                }
                H = 'fixed';
            }
        }
        function b() {
            var J = n();
            var I = q;
            if (l.options.removeOffsets) {
                I = '';
                J = J - D;
            }
            cssOptions = {
                position: 'absolute',
                top: J,
                left: I,
                'margin-left': '0px',
                bottom: ''
            };
            if (!l.options.dontSetWidth) {
                cssOptions.width = G.width();
            }
            G.css(cssOptions);
            H = 'absolute';
        }
        function k() {
            if (!h()) {
                f = -1;
                t.css('display', 'none');
                G.css({
                    'z-index': y,
                    width: '',
                    position: E,
                    left: '',
                    top: e,
                    'margin-left': ''
                });
                G.removeClass('scroll-to-fixed-fixed');
                if (l.options.className) {
                    G.removeClass(l.options.className);
                }
                H = null;
            }
        }
        function v(I) {
            if (I != f) {
                G.css('left', q - I);
                f = I;
            }
        }
        function s() {
            var I = l.options.marginTop;
            if (!I) {
                return 0;
            }
            if (typeof I === 'function') {
                return I.apply(G);
            }
            return I;
        }
        function A() {
            if (!a.isScrollToFixed(G)) {
                return;
            }
            var K = c;
            if (!c) {
                u();
            } else {
                if (h()) {
                    D = G.offset().top;
                    q = G.offset().left;
                }
            }
            var I = a(window).scrollLeft();
            var L = a(window).scrollTop();
            var J = n();
            if (l.options.minWidth && a(window).width() < l.options.minWidth) {
                if (!h() || !K) {
                    o();
                    G.trigger('preUnfixed.ScrollToFixed');
                    k();
                    G.trigger('unfixed.ScrollToFixed');
                }
            } else {
                if (
                    l.options.maxWidth &&
                    a(window).width() > l.options.maxWidth
                ) {
                    if (!h() || !K) {
                        o();
                        G.trigger('preUnfixed.ScrollToFixed');
                        k();
                        G.trigger('unfixed.ScrollToFixed');
                    }
                } else {
                    if (l.options.bottom == -1) {
                        if (J > 0 && L >= J - s()) {
                            if (!x() || !K) {
                                o();
                                G.trigger('preAbsolute.ScrollToFixed');
                                b();
                                G.trigger('unfixed.ScrollToFixed');
                            }
                        } else {
                            if (L >= D - s()) {
                                if (!p() || !K) {
                                    o();
                                    G.trigger('preFixed.ScrollToFixed');
                                    w();
                                    f = -1;
                                    G.trigger('fixed.ScrollToFixed');
                                }
                                v(I);
                            } else {
                                if (!h() || !K) {
                                    o();
                                    G.trigger('preUnfixed.ScrollToFixed');
                                    k();
                                    G.trigger('unfixed.ScrollToFixed');
                                }
                            }
                        }
                    } else {
                        if (J > 0) {
                            if (
                                L + a(window).height() - G.outerHeight(true) >=
                                J - (s() || -m())
                            ) {
                                if (p()) {
                                    o();
                                    G.trigger('preUnfixed.ScrollToFixed');
                                    if (E === 'absolute') {
                                        b();
                                    } else {
                                        k();
                                    }
                                    G.trigger('unfixed.ScrollToFixed');
                                }
                            } else {
                                if (!p()) {
                                    o();
                                    G.trigger('preFixed.ScrollToFixed');
                                    w();
                                }
                                v(I);
                                G.trigger('fixed.ScrollToFixed');
                            }
                        } else {
                            v(I);
                        }
                    }
                }
            }
        }
        function m() {
            if (!l.options.bottom) {
                return 0;
            }
            return l.options.bottom;
        }
        function o() {
            var I = G.css('position');
            if (I == 'absolute') {
                G.trigger('postAbsolute.ScrollToFixed');
            } else {
                if (I == 'fixed') {
                    G.trigger('postFixed.ScrollToFixed');
                } else {
                    G.trigger('postUnfixed.ScrollToFixed');
                }
            }
        }
        var C = function(I) {
            if (G.is(':visible')) {
                c = false;
                A();
            }
        };
        var F = function(I) {
            !!window.requestAnimationFrame ? requestAnimationFrame(A) : A();
        };
        var B = function() {
            var J = document.body;
            if (document.createElement && J && J.appendChild && J.removeChild) {
                var L = document.createElement('div');
                if (!L.getBoundingClientRect) {
                    return null;
                }
                L.innerHTML = 'x';
                L.style.cssText = 'position:fixed;top:100px;';
                J.appendChild(L);
                var M = J.style.height,
                    N = J.scrollTop;
                J.style.height = '3000px';
                J.scrollTop = 500;
                var I = L.getBoundingClientRect().top;
                J.style.height = M;
                var K = I === 100;
                J.removeChild(L);
                J.scrollTop = N;
                return K;
            }
            return null;
        };
        var r = function(I) {
            I = I || window.event;
            if (I.preventDefault) {
                I.preventDefault();
            }
            I.returnValue = false;
        };
        l.init = function() {
            l.options = a.extend({}, a.ScrollToFixed.defaultOptions, i);
            y = G.css('z-index');
            l.$el.css('z-index', l.options.zIndex);
            t = a('<div />');
            H = G.css('position');
            E = G.css('position');
            e = G.css('top');
            if (h()) {
                l.$el.after(t);
            }
            a(window).bind('resize.ScrollToFixed', C);
            a(window).bind('scroll.ScrollToFixed', F);
            if ('ontouchmove' in window) {
                a(window).bind('touchmove.ScrollToFixed', A);
            }
            if (l.options.preFixed) {
                G.bind('preFixed.ScrollToFixed', l.options.preFixed);
            }
            if (l.options.postFixed) {
                G.bind('postFixed.ScrollToFixed', l.options.postFixed);
            }
            if (l.options.preUnfixed) {
                G.bind('preUnfixed.ScrollToFixed', l.options.preUnfixed);
            }
            if (l.options.postUnfixed) {
                G.bind('postUnfixed.ScrollToFixed', l.options.postUnfixed);
            }
            if (l.options.preAbsolute) {
                G.bind('preAbsolute.ScrollToFixed', l.options.preAbsolute);
            }
            if (l.options.postAbsolute) {
                G.bind('postAbsolute.ScrollToFixed', l.options.postAbsolute);
            }
            if (l.options.fixed) {
                G.bind('fixed.ScrollToFixed', l.options.fixed);
            }
            if (l.options.unfixed) {
                G.bind('unfixed.ScrollToFixed', l.options.unfixed);
            }
            if (l.options.spacerClass) {
                t.addClass(l.options.spacerClass);
            }
            G.bind('resize.ScrollToFixed', function() {
                t.height(G.height());
            });
            G.bind('scroll.ScrollToFixed', function() {
                G.trigger('preUnfixed.ScrollToFixed');
                k();
                G.trigger('unfixed.ScrollToFixed');
                A();
            });
            G.bind('detach.ScrollToFixed', function(I) {
                r(I);
                G.trigger('preUnfixed.ScrollToFixed');
                k();
                G.trigger('unfixed.ScrollToFixed');
                a(window).unbind('resize.ScrollToFixed', C);
                a(window).unbind('scroll.ScrollToFixed', F);
                G.unbind('.ScrollToFixed');
                t.remove();
                l.$el.removeData('ScrollToFixed');
            });
            C();
        };
        l.init();
    };
    a.ScrollToFixed.defaultOptions = {
        marginTop: 0,
        limit: 0,
        bottom: -1,
        zIndex: 1000,
        baseClassName: 'scroll-to-fixed-fixed'
    };
    a.fn.scrollToFixed = function(b) {
        return this.each(function() {
            new a.ScrollToFixed(this, b);
        });
    };
})(jQuery);
